import Image, { ImageProps } from 'next/image';

const KImage = ({ src, alt, ...props }: ImageProps) => {
  return (
    <Image
      src={src}
      alt={alt}
      placeholder="blur"
      blurDataURL="./assets/images/gallery.png"
      quality={10}
      {...props}
    />
  );
};

export default KImage;
